import React from 'react';

import { Dna } from 'react-loader-spinner';
import { Styled } from './Styled.styled';

const Loading = () => {
    return (
        <Styled>
            <Dna
                visible={true}
                height="120"
                width="120"
                ariaLabel="dna-loading"
                wrapperStyle={{color:'yellow'}}
                wrapperClass="dna-wrapper"
            />
        </Styled>
    );
};

export default Loading;

