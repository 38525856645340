import React from 'react';


import image from 'src/assets/images/error_404.png';
import { Styled } from './Styled.styled';

const Error404 = () => {
    return (
        <Styled>
            <img src={image} alt="Error" />
        </Styled>
    );
};

export default Error404;
