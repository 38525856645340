import { devices } from 'src/constants/devices';
import styled from 'styled-components';

export default styled.ul`
	margin: 0;
	width: 100%;
	display: flex;
	align-items: center;
	column-gap: ${({ lang }) => lang === "ar" ? "1.5rem" : '1.35rem'};
	list-style: none;
	padding: 0 2rem;
	box-sizing: border-box;
	box-shadow: rgba(99, 99, 99, 0.1) 0px 5px 5px 0px;
	background-color: #fff;
	position: sticky;
	top: 0;
	z-index: 10;

	@media ${devices.md} { 
    	display: none;
  	}

	li {
		display: flex;
		align-items: center;
		position: relative;
		color: var(--primary);
		padding: 0.75rem 0;
		box-sizing: content-box;
		border-bottom: 3px solid transparent;
		border-top: 3px solid transparent;
		${'' /* transition:border-bottom .5s ease; */}

		&:hover {
			border-bottom: 3px solid #e63158;
		}

		&:active,
		&:focus {
			border-bottom: 3px solid #e63158;
		}

		a {
			display: flex;
			align-items: center;
			gap: 0.2rem;
			opacity: 0.8;
			color: var(--primary);
			font-size: 1rem;
			text-decoration: none;
			${'' /* transition: color .4s ease; */}
			letter-spacing: 0.03rem;
		}

		& > a {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 0.2rem;
		}

		.edge {
			display: none;
			position: absolute;
			top: 85%;
			left: 50%;
			height: 15px;
			width: 15px;
			background-color: #fff;
			border-top: solid lightgray 1px;
			border-right: solid lightgray 1px;
			transform: rotate(-45deg);
			z-index: 3;
		}

		.li-menu {
			box-sizing: border-box;
			padding: 0.5rem 0;
			position: absolute;
			top: 100%;
			left: -38px;
			max-width: 220px;
			min-width: 176px;
			height: fit-content;
			border: solid lightgray 1px;
			background-color: #fff;
			border-radius: 2px;
			box-shadow: 0 2px 6px 0 rgba(32, 33, 37, 0.1);
			display: none;

			a {
				padding: 0.25rem 0.9rem;
				transition: padding 0.2s ease;
				opacity: 0.7;

				&:hover,
				&:active {
					cursor: pointer;
					padding: 0.25rem 1.1rem;
					opacity: 1;
				}
			}
		}


		/* box-sizing: border-box;
            padding: .5rem 0;
            position: absolute;
            top: 100%;
            max-width: 220px;
            min-width: 176px;
            height: fit-content;
            border: solid lightgray 1px;
            background-color: #fff;
            border-radius: 2px;
            box-shadow: 0 2px 6px 0 rgba(32, 33, 37, 0.1);
            display: none;

            a {
                padding: 0.2rem 0.9rem;
                opacity: .7;
                
                &:hover {
                    cursor: pointer;
                    opacity: 1;
                }
            }
        } */

   
        &:hover {
            & > a {
                opacity:1
            }
            .edge,
            .li-menu {
                display: block;
            }
        }
    
	
`;
