import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StyledSecNav from './Styled.styled';
import { HiOutlineSearch } from 'react-icons/hi';

import img_1 from "src/assets/images/logo_1.png";
import img_2 from "src/assets/images/logo_2.png";

import img_ar_1 from "src/assets/images/logo_ar_1.png";
import img_ar_2 from "src/assets/images/logo_ar_2.png";

import sm_img_1 from 'src/assets/images/logo_md_1.png';
import sm_img_2 from 'src/assets/images/logo_md_2.png';

import { useTranslation } from 'react-i18next';

function SndNav({ width }) {
    const { t, i18n } = useTranslation();
    const [search, setSearch] = useState()
    const lang = i18n.language;
    const navigate = useNavigate();



    const searchHandler = (e) => {
        e.preventDefault();
        navigate('/search', {
            state: {
                search: search
            }
        });
    }

    return (
        <StyledSecNav lang={lang}>
            <div>
                <img width={width < 601 ? 90 : 200} height={75} src={width < 601 ? sm_img_1 : lang === 'ar' ? img_ar_1 : img_1} alt="وزارة الصحة" />
                <img width={width < 601 ? 90 : 200} height={75} src={width < 601 ? sm_img_2 : lang === 'ar' ? img_ar_2 : img_2} alt="مركز المعلومات والتوثيق" />
            </div>

            <form onSubmit={searchHandler}>
                <input type="text" onChange={(e) => setSearch(e.target.value)} placeholder={t('snd_nav.search')} />
                <button type='submit'>
                    <HiOutlineSearch size={25} />
                </button>
            </form>
        </StyledSecNav>
    )
}

export default SndNav