import React from 'react';

import ReportsList from './Components/ReportsList/ReportsList';


const Statistics = () => {
	return <ReportsList title={'pages.reports.statistics'} url={'/api/reports/statistics/'} />;
};

export default Statistics;
