import React from 'react';

import { Dna } from 'react-loader-spinner';
import Styled from './Styled.styled';

const DataLoading = () => {
    return (
        <Styled>
            <Dna
                visible={true}
                height="120"
                width="120"
                ariaLabel="dna-loading"
                wrapperClass="dna-wrapper"
            />
        </Styled>
    );
};

export default DataLoading;

