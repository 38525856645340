import React from 'react';
import { Routes, Route } from 'react-router-dom';


import 'bootstrap/dist/css/bootstrap.min.css';
import './setup/i18n/i18n';
import './App.css';

import Layout from './layouts/Layout/Layout';
import { Error404 } from './components';
import Home from './pages/home/Components/Home';


import { PostDetail, News } from './pages/news';
import { Center, Plans, Statistics, Legislation, Studies, Guides } from './pages/reports';

import About from './pages/About/About';
import Search from './pages/search/Search';

import ContactUs from './pages/contact/Contactus';

function App() {
	return (
		<Routes>
			<Route path="/" element={<Layout />}>
				<Route index element={<Home />} />
				<Route path="about" element={<About />} />
				<Route path="search" element={<Search />} />
				<Route path="news">
					<Route path="" element={<News />} />
					<Route path=":pk" element={<PostDetail />} />
				</Route>
				<Route path="legislation" element={<Legislation />} />
				<Route path="contactUs" element={<ContactUs />} />
				<Route path="studies" element={<Studies />} />
				<Route path="working/guides" element={<Guides />} />
				<Route path="reports">
					<Route path="center" element={<Center />} />
					<Route path="plans" element={<Plans />} />
					<Route path="statistics/" element={<Statistics />} />
				</Route>


			</Route>
			<Route path="*" element={<Error404 />} />
		</Routes>
	);
}

export default App;
