import { styled } from "styled-components";


export default styled.section`
    margin-top: 1.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.20) 0px 0px 4px;
    transition: box-shadow .1s ease-in-out;
    padding: .75rem 1.2rem;
   
    border-radius: 3px;

    .content{
        flex: .8;
    }



    &:hover{
        box-shadow: rgba(0, 0, 0, 0.20) 0px 1px 7px!important;
    }
    
    .content span{
        color: #e63158;
        font-size: .95rem;
        letter-spacing: .01rem;
        margin-bottom: .5rem;
        font-weight: 300;
    }

    .content .title{
        color: #444;
        letter-spacing: .03rem;
        font-size: 1.2rem;
        margin-top: .5rem;
      
       line-height: 1.9rem;
    }
    .file img
    {
       width: 50px;
        transform: scale(1);
    }
   
    .file img:hover {
        transform: scale(1.2);
        transition: all 0.2s ease-out;
    }

`;



