import React from 'react';

import { useTranslation } from 'react-i18next';

// Swiper Module *********************************
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper';

import 'swiper/css/bundle';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';

import Item from './Item/Item';
import { StyledSwiper } from './Styled.styled';

const CustomSwiper = ({ data }) => {
	const navigationPrevRef = React.useRef(null);
	const navigationNextRef = React.useRef(null);
	const { t, i18n } = useTranslation();

	return (
		<StyledSwiper lang={i18n.language}>
			<Swiper
				effect={'fade'}
				modules={[Navigation, Autoplay, EffectFade, Pagination]}
				spaceBetween={50}
				slidesPerView={1}
				pagination={{
					clickable: true,
				}}
				loop={true}
				autoplay={{
					delay: 5000,
				}}
				navigation={{
					prevEl: navigationPrevRef.current,
					nextEl: navigationNextRef.current,
				}}
				onBeforeInit={(swiper) => {
					swiper.params.navigation.prevEl = navigationPrevRef.current;
					swiper.params.navigation.nextEl = navigationNextRef.current;
				}}
			>
				{data.map((item) => {
					return (
						<SwiperSlide key={Math.random()}>
							{({ isActive }) => {
								return (
									<Item item={item} lang={i18n.language} active={isActive} t={t} />
								);
							}}
						</SwiperSlide>
					);
				})}
			</Swiper>
		</StyledSwiper>
	);
};

export default CustomSwiper;
