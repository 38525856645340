import styled from "styled-components";

import { devices } from "src/constants/devices";

export default styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 1rem 2rem;
    border-bottom: 1px solid #f0f0f0;

    div:first-child{
        display: flex;
        align-items: center;
        gap: 9rem;
        flex-wrap: wrap;

        img{
            height: fit-content; 
            width: ${({ lang }) => lang === "ar" ? "180px" : '220px'};
        }
    }
    
    form{
        position: relative;
        width: 275px;
        
        button {
            position: absolute;
            ${props => (props.lang === 'ar' ? 'left' : 'right')}: 9px;
            z-index: 999;
            border: none;
            height: 100%;
            color: #154877;
            background: unset;
            padding: 0 8px;
            cursor: pointer;

            svg{
                opacity: .7;
                
                &:hover{
                    opacity: 1;
                }
            }
        }

        input {
            width: 100%;
            font-family: cairo;
            font-size: 1rem;
            color: #222;
            border-radius: 50px;

            padding: ${props => (props.lang === 'ar' ?
        '0.7rem 1.2rem 0.7rem 3rem' :
        '0.7rem 3rem 0.7rem 1.2rem')};
            padding: .5rem 1.3rem;
            border: none;
            box-sizing: border-box;
            opacity: .8;
            transition: all .2s ease-in-out;
            letter-spacing: 0.02rem;
            background-color: #eee;
            border: 1px solid #ddd;

            &:hover{
                opacity: 1;
            }

            &:focus {
                outline: none;
                opacity: 1;
            }
        }
        .ar input{
            padding: 0.7rem 1.2rem 0.7rem 3rem ;
        }
    }


    @media only screen and ${devices.md} {
        padding: 2rem;

        div:first-child{
            width: 100%;
            justify-content: space-around;
            gap: 2rem; 

           
        }
        
        form{
            width: 92%;
            margin: auto;
            margin-top: 1.5rem;
        }

        };

        @media only screen and ${devices.sm} {
            div:first-child{
                img{
                    width: 90px;
                }  
            }
        }
   


    
`;