import { styled } from 'styled-components';


export default styled.section`

    margin: 5rem 0;

    h2 {
        color: #154877;
        font-size: 3rem;
        font-weight: 600;
        line-height: 3rem;
        display: flex;
        margin-bottom: 1.25rem;
        gap: .5rem;
        text-transform: capitalize;

        span {
                color: #e63158;
                }
    }
         
        a {
            color: #414141;
            font-size: 1.2rem;
            font-weight: 500;
            line-height: 1rem;
            display: flex;
            align-items: center;
            gap: 1rem;
            text-transform: capitalize;

            .icon {
                    color:var(--primary);
                    padding: 0.7rem;
                    border-radius: 0.4rem;
                    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);      
                }   
            }
            
        hr {
            opacity: .15;
            margin-bottom: 2rem;
        }
`;



