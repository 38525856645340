import { styled } from "styled-components";

export const Styled = styled.div`
    position: fixed;
    z-index: 999;
    height: 100%;
    width: 100%;

    img{
        height: 100%;
        width: 100%;
    }

`;

