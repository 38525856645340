import { styled } from "styled-components";


export const StyledSwiper = styled.div`
    position: relative;
    overflow: hidden;
    
    
    .overlay{
        position: absolute;
        z-index: 2;
        background-color: #04101a57;
        height: 100%;
        width: 100%;
    }

    img{
        width: 100%; 
        height: 550px;
        object-fit: cover;
        object-position: center;
    }

    
    .content{ 
            max-width: 800px;
            position:absolute;
            height: 100%;
            display:flex;
            flex-direction:column;
            justify-content:center;
            padding:0 2rem;
            ${props => props.lang === 'ar' ? "right" : "left"}: 80px;
            z-index: 999;
        
            h2  {
                font-size: 3rem;
                font-weight: 600;
                line-height: 4rem;
                color: #f2f1f1;
                margin-bottom: 1.2rem;
            }

            p{
                margin: .75rem 0 1rem;
                font-size: 1rem;
                font-weight: 400;
                color: #fff;
                opacity: .9;
                letter-spacing: .04rem;
                margin-bottom: 1.5rem;
            }


            @media screen and (max-width: 768px){
                right: 0;
            }


        } 

    a {
                ${props => props.lang === 'ar' ? "right" : "left"}: 85px;
                text-decoration: none;
                color: #fff;
                font-size: 1rem;
                font-weight: 400;
                text-decoration: none;
                color: #fff;
                background: var(--secondary);
                padding: .1rem 1.75rem;
                letter-spacing: 0.03rem;
                text-transform: capitalize;
                border-radius: 3px;
                transition: all .5s ease-in-out;
            
                &:hover{
                    background: #276582;
                    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
                }
        }

    .img {
        position: absolute;
        transition: all ease-in 4s ;
    }

    .zoom {
        animation: zoom 5.5s ease;
    }

    .x-slice {
        animation: zoom 5s ease;
    }

    @keyframes zoom {
        0% {
            transform: scale(1, 1);
        }
        70% {
            transform: scale(1.5, 1.5);
        }
        90% {
            transform: scale(1.5, 1.5);

        }
        100% {
            transform: scale(1.2);
        }
    }

    @keyframes x-slice {
        0% {
            left: 0;
        }
        90% {
            left: 0;
        }
        100% {
            left: -40px;
        }
    }

    .swiper-pagination-bullet {
        width: 10px;
        height: 5px;
        border-radius: 5px;
        margin: 0 1px;
        transition: all ease 0.3s;
        background-color: #fff;
        opacity: 0.6;
        box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 5px;
    }
    .swiper-pagination-bullet-active {
        width: 30px;
        margin: 0 1px;
        transition: all ease 0.35s;
        background-color: var(--secondary);
    }

    .btns {
        height: 60px;
        width: 35px;
        background-color: #121212;
        border-radius: 3px;
        z-index: 3;
        position: absolute;
        top: 175px;
        color: #fff;

        div {
            height: 100%;
            width: 100%;
            background-color: #ccc;
        }
    }
    .l-btn {
        left: 10px;
    }
    .r-btn {
        right: 10px;
    }


    @media screen and (max-width: 578px){
        .content{
            right: 0;

            h2{
                font-size: 1.9rem;
                margin-bottom: 0;
            }
            p{
                margin-top:0;
                font-size:.95rem;
            }
            a{

            }
        }
        img{
            height: 375px;
        }
    }
`;
