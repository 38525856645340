import { styled } from "styled-components";

import img from "src/assets/images/banner_bg.png";


export const StyledAboutUs = styled.section`
    min-height: 500px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
    position: relative;
    padding: 6rem 3rem;
    background-size:cover;
    background-color:#fff;
    background-image: url(${img});
    background-position: top;
    background-repeat: no-repeat;
    justify-content: space-between;

    .container{

        .content {
            flex: 1;
            ${({ lang }) => lang === "ar" ? "padding-left" : "padding-right"}: 3.5rem;

            .title {
                padding: 5px 0px;
                font-weight: bold;

                h2 {
                    margin: 0;
                    color: #e63158;
                    font-size: 3rem;
                    font-weight: 600;
                    line-height: 3rem;
                    display: flex;
                    word-spacing: -0.1rem;
                    margin-bottom: 1rem;
                    gap: .5rem;

                    span {
                        color: #154877;
                    }
                }
            }

            p {
                text-align: ${({ lang }) => lang === "ar" ? "right" : 'left'};
                font-size: 1rem;
                letter-spacing: 0.03rem;
                line-height: ${({ lang }) => lang === "ar" ? "2rem" : '1.75rem'};
                color: #666;
                font-weight: 400;
                margin-bottom: 2rem;
            }

            .us-fo {
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #154877;
                box-sizing: border-box;
                transition: all ease 0.2s;

                a {
                    font-size: 1rem;
                    font-weight: 400;
                    text-decoration: none;
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #e63158;
                    padding: ${({ lang }) => lang === "ar" ? ".6rem 1.4rem" : '.6rem 1.2rem'};
                    letter-spacing: 0.03rem;
                    text-transform: capitalize;
                    border-radius: 5px;
                    gap: 0.25rem;
                    transition: all .2s ease-in-out;
                    
                    span{
                        line-height: 1.2rem;
                    }

                    &:hover {
                        background: #d5274d;
                        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
                    }
                }
            }
        }

        .img-con {
            img {
                box-shadow: rgba(0, 0, 0, 0.20) 0px 10px 20px 0px,
                    rgba(0, 0, 0, 0.10) 0px 0px 0px 1px;
                border-radius: 12px;
                width: 540px;
                height: 330px;
            }
        }
    }

    @media screen and (max-width: 991px){
        padding: 5rem 0rem;
        
        .container {
            flex-wrap: wrap;
            flex-direction: column-reverse;
            margin-top: 2rem;
            
            .content {
                padding: 0;
                margin-top: 3.5rem;
            }
            
            .img-con img{
                width: 100%;
                height: fit-content;
            }
        }

    }

    @media screen and (max-width: 576px){
        padding: 4rem 1.5rem 5rem;

        .container .content{
            margin-top: 2rem;

            .title h2{
                font-size: 2.25rem;
                line-height: 2.25rem;
                gap: .5rem;
                margin-bottom: .75rem;
            }

            p{
                margin-bottom: 1.5rem;
                font-size: .95rem; 
            }
        }
    }

`;

// ***********************************************************

export const StyledArticleCard = styled.div`
    display: flex;
    width: 570px;
    height: 270px;
    border: solid lightgray 1px;
    border-radius: 5px;
    overflow: hidden;

    img {
        transition: all 0.4s ease-in-out;
    }

    &:hover {
        cursor: pointer;
        .ar-img > img {
            transform: scale(1.1);
        }
    }

    .ar-des {
        width: 50%;
        height: 100%;
        background-color: #fff;
        box-sizing: border-box;
        padding: 20px;

        h1 {
            /* font-size: 20px; */
            margin: 0 0 10px 0;
        }
        p {
            margin: 0;
            font-size: 0.875rem;
        }
    }
    .ar-img {
        width: 50%;
        height: 100%;
        overflow: hidden;

        img {
            height: 100%;
            width: 100%;
        }
    }
`;

export const StyledArticle = styled.section`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 40px 0 70px 0;

    .wrapper {
        width: 90%;
        display: flex;
        flex-direction: column;

        .w-head {
            display: flex;
            justify-content: space-between;
            flex-direction: ${({ isEn }) => !isEn && "row-reverse"};
            padding: 5px 0px;

            .title {
                color: ${({ theme, isDark }) =>
        isDark ? theme.color.onSurface : "#5f6267"};

                h2 {
                    text-align: ${({ isEn }) => !isEn && "end"};
                    margin: 0;
                    font-size: 48px;
                    color: #e63158;
                    font-weight: 500;

                    strong {
                        color: #154877;
                    }
                }
            }

            .all-links {
                display: flex;
                align-items: center;
                flex-direction: ${({ isEn }) => !isEn && "row-reverse"};
                gap: 8px;
                color: #154877;

                a {
                    font-size: 25px;
                    font-weight: bold;
                    color: #154877;
                    text-decoration: none;
                }
            }
        }
        .w-body {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 50px;
            padding-top: 30px;
        }
    }
`;

export const StyledSysCard = styled.a`
    width: 300px;
    height: 90px;
    border-radius: 10px;
    /* border: solid lightgray 1px; */
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 5px;

    transition: all ease 0.15s;
    border: solid #154877 0px;

    font-size: 20px;
    font-weight: 400;
    text-align: center;
    text-decoration: none;
    color: #5f6267;

    &:hover {
        cursor: pointer;
        border: solid #154877 3px;
        background-color: #154877;
        color: #fff;
    }

    img {
        background-color: #fff;
        border-radius: 6px;
        height: 100%;
    }
`;

export const StyledGe = styled.div`
    width: 400px;
    height: 100px;

    .title {
        width: fit-content;
        margin-left: ${({ isEn }) => !isEn && "auto"};

        h2 {
            text-align: ${({ isEn }) => !isEn && "end"};
            margin: 0;
            font-size: 48px;
            color: #e63158;
            font-weight: 500;

            strong {
                color: #154877;
            }
        }
    }

    .item {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: space-between;
        flex-direction: ${({ isEn }) => isEn && "row-reverse"};
        align-items: center;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
        box-sizing: border-box;
        border-radius: 3px;
        padding: 3px 10px;
        padding-left: 15px;
        margin-top: 15px;
        font-size: 20px;
        border: solid #154877 0px;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
        color: #384e49;

        &:hover {
            cursor: pointer;
            padding-right: ${({ isEn }) => isEn && "5px"};
            padding-left: ${({ isEn }) => !isEn && "10px"};
            /* border: solid #154877 3px; */
        }

        span {
            color: #384e49;
        }
    }
`;

export const StyledSystems = styled.div`
    width: 731px;

    .title {
        width: fit-content;
        color: #5f6267;
        margin-left: ${({ isEn }) => !isEn && "auto"};

        h2 {
            text-align: ${({ isEn }) => !isEn && "end"};
            margin: 0;
            font-size: 48px;
            color: #e63158;
            font-weight: 500;

            strong {
                color: #154877;
            }
        }
    }

    .sys-body {
        padding: 30px 0px;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        gap: 50px;
    }

`;



