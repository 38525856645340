import { motion } from 'framer-motion';

const Item = ({ item, active, t, lang }) => {

	return (
		active && (
			<div className="item">
				<div className="overlay">
					<motion.div className='content'
						initial={{
							opacity: 1,
						}}
						animate={{
							opacity: [0, 1, 1, 0],
						}}
						transition={{
							ease: 'easeOut',
							times: [0, 0.2, 0.98, 1],
							duration: 5.3,
						}}

					>
						<h2>
							{lang === 'ar' ? t(item.title) : t(item.en_title)}
						</h2>
						<p>
							{lang === 'ar' ? t(item.overview) : t(item.en_overview)}
						</p>
						{item.link ?
							<div>
								<a href={item.link} >
									{t('pages.home.carousel.btn')}
								</a>
							</div>
							:
							null
						}

					</motion.div>
				</div>

				<motion.img
					initial={{ scale: 1 }}
					animate={{ scale: 1.2 }}
					transition={{
						duration: 5.2,
						ease: 'linear',
					}}
					src={item.image}

				/>
			</div>
		)
	);
};

export default Item;
