import { styled } from 'styled-components';

const StyledFooter = styled.footer`

	background:var(--primary);
	margin-top:auto;
	color: #fff;
	font-size: 1rem;
	font-weight: 400;
	text-transform: capitalize;
	text-align: center;
	padding: .75rem 0;
	letter-spacing: .03rem;
	font-family: Cairo !important;
    
	
	.rights-footer img {
		width: 55px;
		height: 55px;
		margin-bottom: 10px;
	}


	&>div:last-child {
		text-align: center;
		background: var(--extra-dark);
		color: #fff;
		padding: 17px 0;
		font-family: var(--ft);
		font-style: normal;
		font-size: 17px;
		line-height: 28px;
		color: #aaa;
		letter-spacing: 0.02em;
		word-spacing: 2px;

			a{
				color:var(--fifth);
				text-decoration:none;
				margin:0 4px;
			
				&:hover{ text-decoration: underline; }
			}
		}

	
`;

export default StyledFooter;
