import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import { List, Side } from '../index';
import { BASE_URL } from 'src/constants/vars';
import { DataLoading, Error500 } from '../../../components'
import NotingFound from '../../../components/NotingFound/NotingFound';

const News = () => {
  const { t, i18n } = useTranslation();
  const [page, setPage] = useState(1)



  const fetchData = async () => {
   
    const res = await fetch(`${BASE_URL}/api/posts/?page=${page}`);
    const jsonData = await res.json();
    return jsonData;
  }

  const { isLoading, error, data, isFetching } = useQuery(
    ["/news", page],
    () => fetchData(),
    { keepPreviousData: true }
  );

  if (isLoading) {
    return <DataLoading />;
  }

  if (error) {
    return <Error500 />;
  }

  return (
    <div className="container news">
      <div className="news-title">
        <h2>{t("trd.News")}</h2>
      </div>

      <hr />

      <div className="row">
        <div className="col-md-8">
          {data.results.length > 0 ? (
            <List
              setPage={setPage}
              data={data}
              isFetching={isFetching}
              lang={i18n.language}
              page={page}
            />
          ) : (
            <NotingFound />
          )}
        </div>
        <div className="col-md-4">
          <Side data={data?.featured} />
        </div>
      </div>
    </div>
  );
};

export default News;
