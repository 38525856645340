import { AiFillHome } from 'react-icons/ai';
import StyledThdNav from './Styled.styled';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

export default function ThdNav() {
	const { t, i18n } = useTranslation();
	return (
		<StyledThdNav lang={i18n.language}>
			<li>
				<NavLink to="/">
					<AiFillHome size={20} />
					{t('trd.home')}
				</NavLink>
			</li>
			<li>
				<NavLink> {t('trd.reports.title')} </NavLink>
				<div className="edge"></div>
				<div className="li-menu">
					<NavLink to="/reports/plans">{t('trd.reports.plans')}</NavLink>
					<NavLink to="/reports/statistics">{t('trd.reports.statistics')}</NavLink>

				</div>
			</li>
			<li>
				<NavLink to="/reports/center">{t('trd.reports.center')}</NavLink>
			</li>
			<li>
				<NavLink to="/legislation">{t('trd.Legislation')}</NavLink>
			</li>
			<li>
				<NavLink to="/studies">{t('trd.health')}</NavLink>
			</li>
			<li>
				<NavLink to="/working/guides">{t('trd.working')}</NavLink>
			</li>
			<li>
				<NavLink to="/news">{t('trd.News')}</NavLink>
			</li>



		</StyledThdNav>
	);
}
