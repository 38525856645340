import React from 'react'
import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import { useRef } from 'react';
import Footer from './Footer/Footer';

import { TopNav, SndNav, TrdNav } from './Nav';

function Layout() {
    const { i18n } = useTranslation();
    const windowSize = useRef({ 'width': window.innerWidth });
    return (
        <div className={['abs-container',i18n.language === 'ar' ? 'ar' : ''].join(' ')}>
            <TopNav />
            <SndNav width={windowSize.current.width} lang={i18n.language} />
            <TrdNav width={windowSize.current.width} />
            <Outlet context={{ lang: i18n.language }} />
            <Footer />
        </div>
    )
}

export default Layout