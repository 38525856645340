import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "react-bootstrap/Pagination";
import DataLoading from "../../../components/DataLoading/DataLoading";
import "./List.css";

const List = ({ data, lang, page, setPage, isFetching }) => {
  const posts = [...data.results];
  const bigPost = posts.shift(0);
  const navigate = useNavigate();


  return isFetching ? (
    <DataLoading />
  ) : (
    <>
      <Link to={`/news/${bigPost.id}`}>
        <div className="card mb-4">
          <img
            src={bigPost.image}
            alt={lang === "ar" ? bigPost.title : bigPost.en_title}
          />

          <div className="card-img-overlay">
            <p className="card-date">
              <small className="date">{bigPost.timesince}</small>
            </p>

            <h4 className="card-title">
              {lang === "ar" ? bigPost.title : bigPost.en_title}
            </h4>

            <p
              className={`card-text ${lang === "ar" ? "text-end" : "text-start"
                }`}
            >
              {lang === "ar" ? bigPost.overview : bigPost.en_overview}
            </p>
          </div>
        </div>
      </Link>

      <div className="newsList">
        <div className="row">
          {posts.map((news) => (
            <div className="col-sm-6 mb-4" key={news.id}>
              <div
                className="card"
                onClick={() => {
                  navigate(`/news/${news.id}`);
                }}
              >
                <img
                  src={news.image}
                  className="card-img-top"
                  alt={lang === "ar" ? news.title : news.en_title}
                />
                <div className="card-body">
                  <div className="card-date">
                    <span>{news.formatted_pub_date}</span>
                  </div>

                  <h4 className="card-title">
                    {lang === "ar" ? news.title : news.en_title}
                  </h4>

                  <p className="card-text">
                    {lang === "ar" ? news.overview : news.en_overview}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div>
        <Pagination size="sm">
          {/* pre */}
          {data.previous !== null && (
            <Pagination.Prev
              onClick={() => {
                setPage((oldPage) => oldPage - 1);
              }}
            />
          )}
          {new Array(data.pages_num).fill(0).map((_, index) => {
            if (index + 1 > page - 5 && index + 1 < page + 5) {
              return (
                <Pagination.Item
                  key={"page-" + index}
                  active={page === index + 1}
                  onClick={() => {
                    setPage(index + 1);
                  }}
                >
                  {index + 1}
                </Pagination.Item>
              );
            }
          })}
          {/* next */}
          {data.next !== null && (
            <Pagination.Next
              onClick={() => {
                setPage((newPage) => newPage + 1);
              }}
            />
          )}
        </Pagination>
      </div>
    </>
  );
};

export default List;
