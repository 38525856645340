import { styled } from "styled-components";


export default styled.section`
   .posts{

        .post{
            border-radius: 3px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            gap: 1.1rem;
            padding: .5rem .75rem;
            margin-bottom: 1rem;
            transition: all ease 0.15s;
            flex-direction: row;
            border: 1px solid #ddd;

                &:hover {
                cursor: pointer;
                color: #4f4f4f;
                box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
            }
        }

        }
        .img-con{
            width: 35%;
            height: 110px;
        }

        .img-con img{
            height: 100%;
            width: 100%;
            border-radius: 2px;
        }

        .text {
            width: 60%;
        }

        .text{
            div{
                p{
                    color: #e63158;
                    font-size: .9rem;
                    letter-spacing: 0.02rem;
                    margin-bottom: .5rem;
                    text-align: ${({ lang }) => lang === "ar" ? "left" : 'right'};
                }
                
                h4{
                    color: var(--primary);
                    font-weight: 600;
                    font-size: 1rem;
                    letter-spacing: 0.025rem;
                    line-height: 1.75rem;
                    text-align: ${({ lang }) => lang === "ar" ? "left" : 'right'};
                }
            }
        }

`;



