import { styled } from "styled-components";


export default styled.section`
    padding: 4rem 0;
   
    .img-wrap{
        width:100%;
        display: flex;
        align-items: center;
        /* justify-content: center; */

        img{
            width: 100%;
            max-height:420px;
            box-shadow: rgba(0, 0, 0, 0.20) 0px 10px 20px 0px,rgba(0, 0, 0, 0.10) 0px 0px 0px 1px;
            border-radius: 5px;
        }
    }

    .title{
        font-family:var("Cairo");
        font-size: 2rem;
        font-weight: 600;
        line-height: 3rem;
    }

    p{
        font-weight: 300;
        font-size: 1rem;

        line-height: ${({ lang }) => lang === "ar" ? "2em" : '2.25rem'};
        letter-spacing: 0.03rem;
        color: #666;
    }

    @media (max-width: 991px){
    
    }

    @media (max-width: 767px){
     

    }
    @media (max-width: 576px){
     

    }
`;



