import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

import Pagination from "react-bootstrap/Pagination";
import { useQuery } from '@tanstack/react-query';
import { IoDocumentTextOutline } from 'react-icons/io5';

import { BASE_URL } from 'src/constants/vars';
import { DataLoading } from 'src/components';


import Styled from './Styled.styled';
import Report from '../Report/Report';

function ReportsList({ title, url }) {
    const { t, i18n } = useTranslation();
    const [page, setPage] = useState(1)

    const { isLoading, data, isFetching } = useQuery(['reportsData', page], async () => {
        const res = await fetch(`${BASE_URL}${url}?page=${page}`);
        const jsonData = await res.json();
        return jsonData;
    });

    const pagesNum = data !== undefined ? data.pages_num : 0;

    if (isLoading || isFetching) {
        return <DataLoading />
    }

    return (
        <Styled className="container">
            <div className='row pb-5'>
                <div className='col-12'>
                    <h4 className='main-title'>
                        <IoDocumentTextOutline />
                        {t(title)}
                    </h4>
                </div>
                {data && data.results.length < 1 ?
                    <div className='col-12'>
                        <p className='empty-msg'>
                            {t("pages.reports.empty_msg")}
                        </p>
                    </div>
                    : data?.results.map(item => {
                        return (<div className='col-12 col-lg-6'>
                            <Report lang={i18n.language} item={item} />
                        </div>)
                    })}

                {data && data.results.length > 1 ?
                    <div className='col-12 mt-3'>
                        <hr />
                        <Pagination size="sm">
                            {/* pre */}
                            {data.previous !== null && (
                                <Pagination.Prev
                                    onClick={() => {
                                        setPage((oldPage) => oldPage - 1);
                                    }}
                                />
                            )}
                            {new Array(pagesNum).fill(0).map((_, index) => {
                                if (index + 1 > page - 5 && index + 1 < page + 5) {
                                    return (
                                        <Pagination.Item
                                            key={"page-" + index}
                                            active={page === index + 1}
                                            onClick={() => {
                                                setPage(index + 1);
                                            }}
                                        >
                                            {index + 1}
                                        </Pagination.Item>
                                    );
                                }
                                return null;
                            })}

                            {/* next */}
                            {data.next !== null && (
                                <Pagination.Next
                                    onClick={() => {
                                        setPage((newPage) => newPage + 1);
                                    }}
                                />
                            )}
                        </Pagination>
                    </div>
                    : null}

            </div>
        </Styled>
    )
}

export default ReportsList