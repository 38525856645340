import React from 'react';

import ReportsList from './Components/ReportsList/ReportsList';


const Center = () => {
    return (<ReportsList title={"pages.reports.center"} url={"/api/reports/center/"} />);
};

export default Center;
