import { styled } from "styled-components";

export default styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        flex:1;
        flex-direction: column;
`;

