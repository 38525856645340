import React from 'react';
import { useTranslation } from 'react-i18next';

import Styled from './Styled.styled';


import { FaPhoneAlt } from 'react-icons/fa';
import { FaLocationDot } from 'react-icons/fa6';
import { BsFacebook } from 'react-icons/bs';
import { MdEmail } from 'react-icons/md';


const data = [
	{
		Icon: FaPhoneAlt,
		value: '+21892000000'
	},
	{
		Icon: FaLocationDot,
		value: 'طرابلس , الطريق الدائري الثاني قريب من جزيرة الدرن'
	},
	{
		Icon: MdEmail,
		value: 'info@seha.ly',
		link: "mailto:info@seha.ly"
	},
	{
		Icon: BsFacebook,
		value: 'pages.contact.facebook_page',
		link: "https://www.facebook.com/profile.php?id=100057696339033"
	}
]



function ContactUs() {
	const { t, i18n } = useTranslation();

	return (
		<Styled>
			<div className="container" lang={i18n.language}>

				<div className="row">
					<div className="col-12">
						<h2>
							{t('pages.contact.title_1')}
							<span>{t('pages.contact.title_2')}</span>
						</h2>
						<hr />
					</div>
					<div className='col-md-6 col-12'>

						{data.map(item => (
							<div className='mb-3'>
								<a href={item.link ? item.link : null}>
									<div className='icon'>
										<item.Icon />
									</div>
									{t(item.value)}
								</a>
							</div>
						))}
					</div>

					<div className='col-md-6 col-12'>
						<iframe width="100%" height="300" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=V48Q+WHG%D8%8C%20%D8%A7%D9%84%D8%B7%D8%B1%D9%8A%D9%82%20%D8%A7%D9%84%D8%AF%D8%A7%D8%A6%D8%B1%D9%8A%20%D8%A7%D9%84%D8%AB%D8%A7%D9%86%D9%8A%D8%8C%20%D8%B7%D8%B1%D8%A7%D8%A8%D9%84%D8%B3+(%D9%85%D8%B1%D9%83%D8%B2%20%D8%A7%D9%84%D9%85%D8%B9%D9%84%D9%88%D9%85%D8%A7%D8%AA%20%D9%88%D8%A7%D9%84%D8%AA%D9%88%D8%AB%D9%8A%D9%82%20-%20%D9%88%D8%B2%D8%A7%D8%B1%D8%A9%20%D8%A7%D9%84%D8%B5%D8%AD%D8%A9)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" title="مركز المعلومات والتوثيق - وزارة الصحة">
						</iframe>
					</div>

				</div>
			</div>
		</Styled >

	);
}

export default ContactUs;
