import { StyledAboutUs } from './Styled.styled';

import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { AiOutlineArrowRight } from 'react-icons/ai';
import image_about from 'src/assets/images/about.jpeg';

const AboutUsSection = ({ lang }) => {
	const { t } = useTranslation();

	return (
		<StyledAboutUs lang={lang}>
			<div className="container d-flex">
				<div className="content">
					<div className="title">
						<h2>
							<span>{t('pages.home.about.about_1')}</span>
							{t('pages.home.about.about_2')}
						</h2>
					</div>
					<p>
						{lang === 'ar'
							? `
							تم إنشاء مركز المعلومات والتوثيق بقطاع الصحة كمركز مستقل يتبع لقطاع الصحة والبيئة ليتولى بشكل عام توفير المعلومات الصحية الخاصة بالصحة والمؤشرات الوطنية في هذا المجال . أُنشــئ المركز بتاريخ 28/ 3 / 2006 م، ضمن هيكلية وزارة الصحة كجهة تابعة وباشر المركز عمله بتاريخ 1/5/2006 م.
							`
							: `
							The Health Sector Information and Documentation Center was established as an independent center affiliated with the health and environment sector to generally provide health information related to health and national indicators in this field. The center was established on 3/28/2006 AD, within the structure of the Ministry of Health as a subsidiary, and the center began its work on 5/1/2006 AD.

							`}
					</p>
					<div className="us-fo">
						<NavLink to="/about" className={lang === 'ar' && 'flex-row-reverse'}>
							<span>{t('pages.home.about.more')}</span>
							<AiOutlineArrowRight size={15} />
						</NavLink>
					</div>
				</div>
				<div className="img-con">
					<img src={image_about} alt="About Us" loading='lazy' />
				</div>
			</div>
		</StyledAboutUs>
	);
};

export default AboutUsSection;
