import React from 'react';


import image from 'src/assets/images/error.png';
import { Styled } from './Styled.styled';

const Error500 = () => {
    return (
        <Styled>
            <img src={image} alt="Error" />
        </Styled>
    );
};

export default Error500;
