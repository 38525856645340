import React from 'react';
import { useTranslation } from 'react-i18next';

import img from 'src/assets/images/about.jpeg';

import './About.css';



function About() {
	const { t, i18n } = useTranslation();

	return (
		<div className="container about-us" lang={i18n.language}>
			<div>
				<h2>
					{t('pages.home.about.about_1')}
					<span>{t('pages.home.about.about_2')}</span>
				</h2>
			</div>
			<hr />
			<div className="row">
				<div className="col-lg-6 col-12 col-text">
					<p className={i18n.language === 'ar' ? 'text-end' : 'text-start'}>
						{i18n.language === 'ar'
							?
							<p>
								تم إنشاء مركز المعلومات والتوثيق بقطاع الصحة  كمركز مستقل يتبع لقطاع الصحة والبيئة ليتولى بشكل عام توفير المعلومات الصحية الخاصة بالصحة والمؤشرات الوطنية في  هذا المجال .
								أُنشــئ المركز بتاريخ  28/ 3 / 2006 م، ضمن هيكلية وزارة الصحة كجهة تابعة وباشر المركز عمله بتاريخ 1/5/2006 م.
								<br />

								ومنذ ذلك التاريخ فقد اصدر المركز سلسة من التقارير الإحصائية السنوية ونفذ عدد من الدراسات والمسوحات الصحية وبحوث النظم الصحية التي ساهمت في توجيه السياسات الصحية ، كما قدم من خلال ادارة التوثيق والنشر خدماته لعدد كبير من الباحثين الذين يقصدون المركز للحصول على المراجع الإحصائية التي تتوفر بمكتبة إدارة التوثيق والنشر .
								<br />

								كما يعتز المركز بامتلاك موقع الكتروني غني بالعديد من المعلومات والتقارير الإحصائية والإدلة ونتائج المسوحات الصحية تحت اسم نطاق (www.seha.ly ).
								<br />

								لقد عمل المركز على صياغة الإستراتيجية الخاصة بتطوير نظام المعلومات الصحية والتي كان من أهم مكوناتها استحداث نظام الكتروني لجمع البيانات الروتينية من المرافق الصحية وهو المعروف بنظام المعلومات الصحية المناطقي (DHIS2)
								<br />

							</p>

							:

							<p>
								The Health Sector Information and Documentation Center was established as an independent center affiliated with the health and environment sector to generally provide health information related to health and national indicators in this field.
								<br />
								The center was established on 3/28/2006 AD, within the structure of the Ministry of Health as a subsidiary, and the center began its work on 5/1/2006 AD.
								<br />
								Since that date, the Center has issued a series of annual statistical reports and carried out a number of studies, health surveys, and health systems research that have contributed to directing health policies. It has also provided, through the Documentation and Publishing Department, its services to a large number of researchers who go to the Center to obtain statistical references that are available in the Department’s library. Documentation and publishing.

							</p>


						}
					</p>
				</div>
				<div className="col-lg-6 col-sm-12 col-img">
					<img src={img} alt="news" className="about-img" />
				</div>
				<div className="col-12 col-text-2">
					{i18n.language === 'ar' ?
						<p>
							كما تم تنفذ عشرات الدورات التدريبية التي تساهم في تفعيل البرنامج على مستوى المرافق الصحية والبلديات والمناطق الصحية ولقد بدأت هذه المنظومة تعمل كمنصة لجمع البيانات الإحصائية الروتينية وتنفيذ المسوحات الصحية كما تم تسجيل موقعها تحت اسم نطاق (www.dhis2ly.ly ).
							<br />
							و خلال العام 2022، تمكن المصدر من اصدار تقارير السنوات 2019 و 2020 ويجري اعداد التقرير 2021 ، كم صدر خلال هذا العام التقرير الخاص بإحصاءات الأحوال المدنية عن المواليد والوفيات  والمؤشرات المتعلقة بها حيث تمكن المركز من تغطية العجز في بياناتها خلال الفترة من 2013 الى 2020 ، كما يجري اعداد التقرير 2021 و 2022 بالتعاون مع مصلحة الأحوال المدنية .
							<br />
							والجدير بالذكر ان المركز يتولى تزويد المنظمات الدولية بالمؤشرات الصحية وغيرها من المؤشرات ذات علاقة بالصحة وعلى الأخص منظمة الصحية العالمية.
							وأخيراً قام  المركز خلال هذا العام بتوقيع اتفاقية مع شركة المدار على شراء مساحة بسحابة المدار حيث تم تخزين نظام المعلومات الإحصائي الصحي بها ، بالإضافة الى موقع البوابة الإلكترونية لقطاع الصحة التي انجزت خلال هذا العام تحت اسم نطاق (www.moh.gov.ly) .
							<br />
							<span>
								نسأل الله  العلي العظيم  بأن تكون اصدارات المركز ذات فائدة لصناع القرار والباحثين لما يعينهم على إعداد الخطط وصناعة القرارات الرشيدة
							</span>
						</p>
						:
						<p>
							
							The Center is also proud to own a website rich with a wealth of information, statistical reports, evidence and results of health surveys under the domain name (www.seha.ly).
							<br />
							The Center worked on formulating a strategy for developing the health information system, one of the most important components of which was the development of an electronic system for collecting routine data from health facilities, which is known as the District Health Information System (DHIS2).
							Dozens of training courses have also been implemented that contribute to activating the program at the level of health facilities, municipalities and health districts. This system has begun to work as a platform for collecting routine statistical data and implementing health surveys, and its website has been registered under the domain name (www.dhis2ly.ly).
							<br />
							During the year 2022, the source was able to issue reports for the years 2019 and 2020, and the 2021 report is being prepared. During this year, the report on civil status statistics on births and deaths and related indicators was issued, as the center was able to cover the deficit in its data during the period from 2013 to 2020. The 2021 and 2022 reports are also being prepared in cooperation with the Civil Status Authority.
							<br />
							It is worth noting that the center is responsible for providing international organizations with health indicators and other health-related indicators, especially the World Health Organization.
							Finally, during this year, the Center signed an agreement with Al-Madar Company to purchase space in Al-Madar cloud, where the health statistical information system was stored, in addition to the electronic portal website for the health sector, which was completed during this year under the domain name (www.moh.gov.ly).
							<br />
							<span>
								We ask God Almighty that the center’s publications will be of benefit to decision-makers and researchers, helping them prepare plans and make rational decisions.
							</span>
						</p>
					}
				</div>
			</div>
		</div>
	);
}

export default About;
