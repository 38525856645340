import { styled } from 'styled-components';


export const Styled = styled.header`
    padding: 5rem 0;

    .title {
        display: flex;
        justify-content: space-between;
        color: #5f6267;
        
        h2 {
            margin: 0;
            color: #e63158;
            font-size: 3rem;
            font-weight: 600;
            line-height: 3rem;
            display: flex;
            gap: .5rem;
            word-spacing: -0.1rem;
            margin-bottom: .5rem;

            strong {
                color: #154877;
            }
        }
    }
    


`;

