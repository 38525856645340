/* eslint-disable no-unused-vars */
import React, { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { BASE_URL } from 'src/constants/vars';
import { useQuery } from '@tanstack/react-query';


// sections
import CustomSwiper from './Swiper/Swiper';

import Article from 'src/pages/home/Components/Featured/Article';
import AboutUsSection from 'src/pages/home/Components/AboutUs/AboutUs';

import LoadingScreen from 'src/components/Loading/Loading';
import { DataLoading, Error500 } from 'src/components';



const News = lazy(() => import('./News/News'));
const Systems = lazy(() => import('./Systems/Systems'));
const Orgs = lazy(() => import('./Orgs/Orgs'));



const Home = () => {
	const { t, i18n } = useTranslation();

	const { isLoading, error, data } = useQuery(['homeData'], async () => {
		const res = await fetch(`${BASE_URL}/api/core/`);
		const jsonData = await res.json();
		return jsonData;
	});

	if (isLoading) {
		return <LoadingScreen />;
	}

	if (error) {
		return <Error500 />;
	}

	return (
		data ?
			<>
				<CustomSwiper data={data.ads} />
				<AboutUsSection lang={i18n.language} />


				<Suspense fallback={<div className='my-5 py-5'>
					<DataLoading />
				</div>}>
					<section className="articles-orgs-section">
						<div className="container">
							<div className="row">
								<div className="col-12 col-lg-8">
									<Article t={t} lang={i18n.language} data={data.latest} />
								</div>
								<div className="col-12 col-lg-4">
									<Systems t={t} lang={i18n.language} data={data.softwares} />
									<Orgs t={t} lang={i18n.language} data={data.orgs} />
								</div>
							</div>
						</div>
					</section>
					<News t={t} lang={i18n.language} data={data.latest} />
				</Suspense>

			</> :
			<h1>No Data Found</h1>
	);
};

export default Home;
