import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

import Styled from './Styled.styled';

const Side = ({ data }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const postClickHandler = (e, i) => {
		navigate(`/news/${i}`, { replace: true })
	}
	return (
		<Styled>
			<div className="reportTitle mb-3">
				<h4>
					{t('pages.news.side')}
					<hr />
				</h4>
			</div>
			<div className='posts'>
				{data.map(item => (
					<div className="post" key={item.title} onClick={(e) => postClickHandler(e, item.id)}>
						<div className="img-con">
							<img src={item.image} alt="images"
								className='' />
						</div>
						<div className="text">
							<div className="">
								<p className="">
									{item.formatted_pub_date}
								</p>
								<h4 className="">
									{item.title}
								</h4>
							</div>
						</div>
					</div>
				))}

			</div>

		</Styled>
	);
};

export default Side;
