import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useParams } from "react-router-dom";
import { DataLoading } from 'src/components';


import Styled from './Styled.styled';
import { BASE_URL } from 'src/constants/vars';


const PostDetail = () => {
    const { t, i18n } = useTranslation();
    const lang = i18n.language;
    const params = useParams();


    const { isLoading, error, data } = useQuery(['post_detail'], async () => {
        const res = await fetch(`${BASE_URL}/api/posts/${params.pk}/`);
        const jsonData = await res.json();
        return jsonData;
    });


    if (isLoading) {
        return <DataLoading />
    }


    return (
        <Styled >
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-8  mb-4">
                        <div className="img-wrap">
                            <img src={data.image} alt="news" />
                        </div>
                    </div>
                    <div className="col-12 col-lg-8">
                        <div className="mb-2">

                            <h2 className="title">
                                {lang === 'ar' ? data.title : data.en_title}
                            </h2>
                            <p className="text-danger fw-semibold mb-2">
                                {data.formatted_pub_date}
                            </p>
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: lang === 'ar' ? data.content : data.en_content }}>
                        </div>
                    </div>

                </div>
            </div>

        </Styled >
    );
};

export default PostDetail;
