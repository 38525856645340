import { styled } from "styled-components";


export const StyledArticle = styled.section`
    padding: 6rem 0;
    padding-${({ lang }) => lang === 'ar' ? "left" : "right"}: 1rem;

    header {
        display: flex;
        justify-content: space-between;
        padding: 0;

        .title {
            h2 {
                margin: 0;
                color: #e63158;
                font-size: 2.5rem;
                font-weight: 600;
                line-height: 3rem;
                display: flex;
                gap: .4rem;
                word-spacing: -0.1rem;
                margin-bottom: 0;

                strong {
                    color: #154877;
                    font-weight: 600;
                }
            }
        }
    }

    hr{
        opacity: .15;
        margin-bottom: .75rem;
    }

    .articles {
        padding: 1.25rem 0;

        & div:last-child{
            margin-bottom: 0;
        }
    }
    
    @media screen and (max-width: 991px){
        padding: 4rem 0 2rem;

        .title {
            h2 {
                font-size: 2rem !important;
            }
        }
        
        hr{
            margin: .5rem 0;
        }

        .articles{
            padding: 1.25rem 0 0;
            display: flex;
            flex-wrap: wrap;
            gap: 3rem;
        }
    }

    @media screen and (max-width: 576px){
        padding: 4rem 1.5rem 2rem;
    }
  
`;


export const StyledArticleCard = styled.div`
    display: flex;
    justify-content: space-between;
    border: solid #eee 1px;
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 2rem;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.10) 0px 4px 8px;

    .img-wrap {
        min-height:300px;
        max-height:350px;
        width: 340px;
        overflow: hidden;
        object-fit: cover;
        object-position: center;
        background-color: #ddd;
        
        img {
            height: 100%;
            width: 100%;
            transition: all 0.4s ease-in-out;
        }
    }

    &:hover {
        
        .img-wrap > img {
            transform: scale(1.1);
        }

    }

    .content {
        width: 70%;
        padding: 2rem 1.5rem;

        h2{
            margin-bottom: ${({ lang }) => lang === "ar" ? "1rem" : '.75rem'};
            color: #154877;
            text-transform: capitalize;
            font-size: 1.2rem;
            line-height: 2.4rem;
            font-weight: 600;
        }

        p{
            font-size: ${({ lang }) => lang === "ar" ? ".9rem" : '.95rem'};;
            overflow: hidden;
            color: #666;
            letter-spacing: 0.03rem;
            line-height: ${({ lang }) => lang === "ar" ? "2rem" : '1.75rem'};
        }

        .date{
            color: #e63158;
            font-size: .9rem;
            letter-spacing: 0.01rem;
        }
    }

    @media screen and (max-width: 992px) {
        flex-direction: column-reverse;
        margin-bottom: 0;
      
        .img-wrap {
            max-height: 370px;
            width: 100%;
        }
        
        .content {
            width: 100%;

            h2{
                font-size: 1.6rem;
            }
        }


    }
 
`;



