import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { HiOutlineTranslate, HiPhone, HiInformationCircle, HiMenu } from 'react-icons/hi';


// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
	const [toggleButton, setToggleButton] = useState(false);
	const [dropdownToggle, setDropdownToggle] = useState(false);

	const { t, i18n } = useTranslation();

	const langChangeHandler = () => {
		i18n.changeLanguage(i18n.language === 'ar' ? 'en' : 'ar');
	};

	return (
		<nav className="navbar topNav">
			<div className="container-fluid">
				<div className="d-lg-flex d-none hide-mobile items">
					<div className="item" onClick={langChangeHandler}>
						<HiOutlineTranslate />
						<span>{t('top_nav.items.lang')}</span>
					</div>
					<NavLink className="item" to="/contactUs">
						<HiPhone />
						<span>{t('top_nav.items.contact')}</span>
					</NavLink>
					<NavLink className="item" to="/about">
						<HiInformationCircle />
						<span>{t('top_nav.items.about')}</span>
					</NavLink>
				</div>
				<div className='toggler-btn d-lg-none'>
					<button
						className="navbar-toggler"
						type="button"
						data-bs-toggle="offcanvas"
						data-bs-target="#offcanvasNavbar"
						aria-controls="offcanvasNavbar"
						onClick={() => setToggleButton(!toggleButton)}
					>
						<HiMenu size={22} />
					</button>
				</div>
				<div
					className={`offcanvas offcanvas-end ${toggleButton ? 'show' : ''}`}
					tabIndex="-1"
					id="offcanvasNavbar"
					aria-labelledby="offcanvasNavbarLabel"
				>
					<div className="offcanvas-header">
						<h5 className="offcanvas-title" id="offcanvasNavbarLabel">
							{/* Offcanvas */}
						</h5>
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="offcanvas"
							aria-label="Close"
							onClick={() => setToggleButton(!toggleButton)}
						></button>
					</div>

					<div className="offcanvas-body">
						<ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
							<li className="nav-item">
								<NavLink className="nav-link" to="/">
									{t('trd.home')}
								</NavLink>
							</li>
							{ }
							<li
								className="nav-item dropdown"
								onClick={() => setDropdownToggle(!dropdownToggle)}
							>
								<NavLink
									className={`nav-link dropdown-toggle ${dropdownToggle ? 'show' : ''
										}`}
									href="#"
									role="button"
									data-bs-toggle="dropdown"
									aria-expanded="false"
								>
									{t('trd.reports.title')}
								</NavLink>
								<ul className={`dropdown-menu ${dropdownToggle ? 'show' : ''}`}>
									<li className="nav-item">
										<NavLink className="dropdown-item" to="/reports/plans">
											{t('trd.reports.plans')}
										</NavLink>
									</li>
									<li className="nav-item">
										<NavLink className="dropdown-item" to="/reports/statistics">
											{t('trd.reports.statistics')}
										</NavLink>
									</li>
									<li className="nav-item">
										<NavLink className="dropdown-item" to="/reports/center">
											{t('trd.reports.center')}
										</NavLink>
									</li>
								</ul>
							</li>
							<li className="nav-item">
								<NavLink className="nav-link" to="/legislation">
									{t('trd.Legislation')}
								</NavLink>
							</li>
							<li className="nav-item">
								<NavLink className="nav-link" to="/studies">
									{t('trd.health')}
								</NavLink>
							</li>
							<li className="nav-item">
								<NavLink className="nav-link" to="/working/guides">
									{t('trd.working')}
								</NavLink>
							</li>

							<li className="nav-item">
								<NavLink className="nav-link" to="/news">
									{t('trd.News')}
								</NavLink>
							</li>
						</ul>
						<hr />
						<ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
							<li className="nav-item item my-2" onClick={langChangeHandler}>
								<HiInformationCircle />
								<span>{t('top_nav.items.lang')}</span>
							</li>
							<li className="nav-item my-2">
								<NavLink className="item" to="/contactUs">
									<HiPhone />
									<span>{t('top_nav.items.contact')}</span>
								</NavLink>
							</li>
							<li className="nav-item item my-2">
								<NavLink className="item" to="/about">
									<HiOutlineTranslate />
									<span>{t('top_nav.items.about')}</span>
								</NavLink>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</nav>
	);
};
