import { styled } from 'styled-components';


export default styled.div`
        padding-bottom: 2rem;
        
        .title {
            margin-top:2rem;
            
            h4{
                margin: 0;
                font-size:1.9rem;
                font-weight: 600;
                line-height: 3rem;
                display: flex;
                gap: .15rem;
                margin-bottom: .2rem;
                color: var(--primary);

                span{
                    color: #e63158;
                    margin:0 .5rem;
                }
            }
            hr {
                    opacity: 0.15;
                }
        }

        .posts{
            padding: 0 0.9rem;
            list-style: none;

            li{
                display: flex;
                align-items: center;
                gap: .5rem;
                margin-bottom:1rem;

                &::marker{
                    color: #666;
                }

                div{
                    color: var(--primary);
                }

                a{
                    text-decoration: none;
                    color: #666;
                    font-weight: bold;
                    font-size: 1.2rem;

                    &:hover,
                    &:active,
                    &:focus{
                        color: #414141;
                    }
                }

}
        }


`;


