import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import Pagination from "react-bootstrap/Pagination";

import { IoNewspaperOutline } from "react-icons/io5";
import { DataLoading, Error500 } from 'src/components';

import Styled from './Styled.styled';
import { BASE_URL } from 'src/constants/vars';

function Search() {
    const { t, i18n } = useTranslation();
    const { state } = useLocation();
    const [page, setPage] = useState(1)
    const search = state ? state.search : ''


    const fetchData = async () => {
        const res = await fetch(`${BASE_URL}/api/posts/?page=${page}&search?search=${search}`);
        const jsonData = await res.json();
        return jsonData;
    }

    const { isLoading, error, data, isFetching } = useQuery(
        ["searchData", search, page],
        () => fetchData(),
        { keepPreviousData: true }
    );



    const pagesNum = data !== undefined ? data.pages_num : 0

    const posts = data !== undefined ? data.results : [];

    if (isLoading || isFetching) {
        return <DataLoading />;
    }

    if (error) {
        return <Error500 />;
    }



    return (
        <Styled>
            <div className="container">
                <div className='col-12'>
                    <div className="title">
                        <h4>
                            {t("pages.search.title")}
                            <span>
                                "{search}"
                            </span>
                            <small>
                                ( {data.count} )
                            </small>
                        </h4>
                        <hr />
                    </div>
                </div>

                <div className='col-12'>
                    <ul className='posts'>
                        {posts.length > 0 ?

                            posts.map(item => (
                                <li>
                                    <div>
                                        <IoNewspaperOutline size={22} />
                                    </div>
                                    <Link to={`/news/${item.id}`}>
                                        {i18n.language === 'ar' ? item.title : item.en_title}
                                    </Link>
                                </li>
                            ))

                            :
                            <li>
                                <h4>
                                    {t('pages.search.empty_msg')}
                                </h4>
                            </li>
                        }

                    </ul>

                    {data && data.results.length > 1 ?
                        <div className='col-12 mt-3'>
                            <hr />
                            <Pagination size="sm">
                                {/* pre */}
                                {data.previous !== null && (
                                    <Pagination.Prev
                                        onClick={() => {
                                            setPage((oldPage) => oldPage - 1);
                                        }}
                                    />
                                )}
                                {new Array(pagesNum).fill(0).map((_, index) => {
                                    if (index + 1 > page - 5 && index + 1 < page + 5) {
                                        return (
                                            <Pagination.Item
                                                key={"page-" + index}
                                                active={page === index + 1}
                                                onClick={() => {
                                                    setPage(index + 1);
                                                }}
                                            >
                                                {index + 1}
                                            </Pagination.Item>
                                        );
                                    }
                                    return null;
                                })}

                                {/* next */}
                                {data.next !== null && (
                                    <Pagination.Next
                                        onClick={() => {
                                            setPage((newPage) => newPage + 1);
                                        }}
                                    />
                                )}
                            </Pagination>
                        </div>
                        : null}
                </div>

            </div>
        </Styled>
    )
}

export default Search