import React from 'react';
import { useTranslation } from 'react-i18next';

import StyledFooter from './Styled.styled';
import image from 'src/assets/images/almotkaml.png';


function Footer() {

	const { t } = useTranslation();
	return (
		<StyledFooter>
			<div className="rights-footer">
				<div className="container">
				
					<div className="text-1">
						All rights reserved © 2023
						<a href="https://www.facebook.com/people/%D9%85%D8%B1%D9%83%D8%B2-%D8%A7%D9%84%D9%85%D8%B9%D9%84%D9%88%D9%85%D8%A7%D8%AA-%D9%88%D8%A7%D9%84%D8%AA%D9%88%D8%AB%D9%8A%D9%82-%D9%88%D8%B2%D8%A7%D8%B1%D8%A9-%D8%A7%D9%84%D8%B5%D8%AD%D8%A9/100057696339033/" >
							Information Health Center
						</a>

					</div>
				
				</div>
			</div>

		</StyledFooter>
	);

}

export default Footer;
