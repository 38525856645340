import React from 'react';
import { StyledArticle, StyledArticleCard } from './Styled.styled';
import { useNavigate } from 'react-router-dom';


import Title from '../Title/Title';



const Card = ({ item, lang }) => {
	const navigate = useNavigate();
	return (
		<StyledArticleCard onClick={() => {
			navigate(`/news/${item.id}`);
		}}>
			<div className="content">

				<div className="date">{item.formatted_pub_date}</div>

				<h2>
					{lang === 'ar' ? item.title : item.en_title}
				</h2>

				<p>
					{lang === 'ar' ? item.overview : item.en_overview}
				</p>

			</div>
			<div className="img-wrap">
				<img
					src={item.image}
					alt={lang === 'ar' ? item.title : item.en_title}
					loading='lazy'
					width={340}
					height={300}

				/>
			</div>
		</StyledArticleCard>
	);
};

const Article = ({ t, lang, data }) => {

	return (
		<StyledArticle lang={lang}>
			<Title>
				<strong>{t('pages.home.latest.title.part_1')}</strong>

				{t('pages.home.latest.title.part_2')}
			</Title>
			<hr />

			<div className="articles">
				{data.map((item) => (
					<Card key={item.id} item={item} lang={lang} />
				))}
			</div>
		</StyledArticle>
	);
};

export default Article;
