import { styled } from "styled-components";


export default styled.section`

    .main-title{
        display: flex;
        align-items: center;
        gap:.4rem;
        margin: 0;
        margin-bottom: 0px;
        font-size: 1.7 !important;
        font-weight: 600;
        line-height: 2rem;
        display: flex;
        color: var(--primary);
        letter-spacing: .03rem;
        margin-top: 2rem;
        border-bottom: 2px solid #efefef;
        padding: 1rem 0;

    }
    
    .empty-msg{
        text-align: center;
        font-size: 1.3rem;
        color: #414141;
        margin-top:1rem
    }
`;



