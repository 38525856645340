import { Styled } from '../Title/Styled.styled';

const Title = ({ children }) => {
    return (<Styled>
        <div className="title">
            <h2>
                {children}
            </h2>
        </div>
        <hr />
    </Styled>
    )
}


export default Title