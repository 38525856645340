import React from 'react';

import Styled from './Styled.styled';

import pdf from 'src/assets/images/pdfImg.png';

const Report = ({ item, lang }) => {
    return (
        <Styled >
            <div className="content">
                <span className="date">
                    {item.date}
                </span>
                <h4 className="title">
                    {lang === 'ar' ? item.title : item.en_title}
                </h4>
            </div>
            <a className="file" href={item.file}>
                <img src={pdf} alt="pdf" />
            </a>
        </Styled>
    );
};

export default Report;
