import React from "react";

import Styled from "./Styled.styled";

import notFound_image from "../../assets/images/notFound.jpg";

const NotingFound = () => {
  return (
    <Styled>
      <img src={notFound_image} alt="notingFound" />
    </Styled>
  );
};

export default NotingFound;
